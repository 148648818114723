<template lang="pug">
.page-login.py-12
  v-container.pa-5(:style="{ maxWidth: '600px' }")
    v-row
      v-col.login-area.pa-12.px-md-12
        template(v-if="!showResetPassword")
          v-img.mx-auto.mb-12(src="@/assets/logos/logo_unitfinancials_500.png", width="300px", contain)
          p Please log in.
          v-text-field(autofocus label="Your Email", placeholder="your.name@company.com", v-model="username", hint="Required")
          v-text-field(
            label="Your Password",
            placeholder="xyz123",
            v-model="password",
            :type="showPasswordInput ? 'text' : 'password'",
            hint="Your password is at least 8 characters with at least 1 number",
            :append-icon="showPasswordInput ? 'mdi-eye' : 'mdi-eye-off'",
            @click:append="() => (showPasswordInput = !showPasswordInput)"
            @keydown.enter="doLogin"
          )
          v-row.mt-4.px-3.text-center(justify="center", justify-md="space-between", align="center")
            v-btn.mb-3(color="primary", :loading="loading", :disabled="!readyToProceed", @click="doLogin")
              v-icon.mr-1 mdi-arrow-right 
              span Log In
            v-btn.mb-3(color="primary", outlined, to="/onboarding/InviteeJoin")
              v-icon.mr-1 mdi-email 
              span Use invite code

          .errors.mt-3
            .login-error.red--text(v-if="loginError") Error logging in: {{ loginError }}
            .reset-complete.mt-2(v-if="resetComplete") We sent an email to {{ username }} containing a link to reset your password.

          //- .text-center.mt-3(v-if="!resetComplete")
          //-     a.font-style-oblique.no-border(href="#" @click.prevent="showResetPassword=true")
          //-     em Did you forget your password?

        template(v-if="showResetPassword")
          p Please enter your email to receive a password reset link.
          v-text-field(label="Your Email", placeholder="your.name@company.com", v-model="username", hint="Required")

          v-row.mt-4.px-3.text-center(justify="center", justify-md="space-between", align="center")
            v-btn.mb-3(color="primary", :loading="loading", :disabled="!isValidEmail(username)", @click="doReset")
              span Reset password
              v-icon.ml-2 mdi-reset
            v-btn.mb-3(color="primary", outlined, @click="showResetPassword = false")
              span ...back to login

          .errors.mt-3
            .reset-error.error--text(v-if="resetError") Error resetting your password: {{ resetError }}
    v-row
      v-col.text-center.pa-6
        a.nav-link(href="/onboarding/CreateSystem") Sign up to create new Brand Space
</template>

<script>
import { call } from "vuex-pathify";
import { isValidEmail, isValidPassword } from "@/helpers.js";
export default {
  name: "LoginPage",
  computed: {
    readyToProceed() {
      return isValidEmail(this.username) && isValidPassword(this.password);
    }
  },
  data() {
    return {
      username: "",
      password: "",
      loginError: null,
      loading: false,
      showResetPassword: false,
      showPasswordInput: false,
      resetComplete: false,
      resetError: null
    };
  },
  methods: {
    ...call({ signIn: "User/signIn", bindUserData: "User/bindUserData" }),
    isValidEmail(str) {
      return isValidEmail(str);
    },
    async doLogin() {
      try {
        this.loading = true;
        this.loginError = null;
        const loginRes = await this.signIn({
          email: this.username,
          password: this.password
        });

        if (loginRes && loginRes.error) {
          this.loginError = loginRes.error;
          this.loading = false;
        } else if (loginRes && loginRes.user) {
          const originalPath =
            this.$route.query && this.$route.query.originalPath
              ? this.$route.query.originalPath
              : "/";
          if (
            originalPath &&
            this.$route.path &&
            this.$route.path !== originalPath
          ) {
            this.$router.replace(originalPath);
          }
        } else {
          this.loginError = "There was an error logging in.";
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async doReset() {
      this.resetError = null;
      this.loading = true;
      const resetRes = await this.resetPassword({ email: this.username });
      window.debug("Got res of reset", resetRes);
      if (resetRes && resetRes.error) {
        this.resetError = resetRes.error.message;
      } else {
        this.showResetPassword = false;
        this.resetComplete = true;
      }

      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
.login-area {
  border: 2px solid rgba($primary, 0.2);
  border-radius: 5px;
}
.nav-link {
  text-decoration: none;
}
.nav-link:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
}
</style>
